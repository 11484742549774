import { Fragment, useState } from "react"

import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Checkbox } from "@mui/material"

import { Modal } from "shared/ui/modal/Modal"
import { MyCheckbox } from "shared/ui/button/MyCheckbox"
import { ErrorMsg } from "shared/ui/input/ErrorMsg"

export function AddMasterService({
  active,
  setActive,
  categories,
  currentServices,
  onSubmit,
}) {
  const [shownSubs, setShownSub] = useState([])
  const [mainService, setMainService] = useState([])

  function showSub(id) {
    if (shownSubs.includes(id)) {
      setShownSub(shownSubs.filter((item) => item !== id))
    } else {
      setShownSub([...shownSubs, id])
    }
  }

  function showMainServices(id) {
    if (mainService.includes(id)) {
      const newList = mainService.filter((item) => item !== id)
      setMainService([...newList])
    } else {
      setMainService([...mainService, id])
    }
  }

  const [shownServices, setShownServices] = useState([])
  function showServices(id) {
    if (shownServices.includes(id)) {
      setShownServices(shownServices.filter((item) => item !== id))
    } else {
      setShownServices([...shownServices, id])
    }
  }

  const newFunc = (id) => {
    showSub(id)
    showMainServices(id)
  }

  const [selectedServices, setSelectedServices] = useState(currentServices)
  function addService(service) {
    if (selectedServices.some(({ id: oldServ }) => oldServ === service.id)) {
      setSelectedServices(
        selectedServices.filter(({ id: old }) => old !== service.id)
      )
    } else {
      setSelectedServices([...selectedServices, service])
    }
  }

  const [touched, setTouched] = useState(false)
  function submit() {
    if (selectedServices.length) {
      onSubmit(selectedServices)
      setActive(false)
    } else {
      setTouched(true)
    }
  }

  function reset() {
    setActive(false)
  }

  return (
    <Modal
      className='sm:max-w-sm min-h-[428px] max-w-[358px] max-h-[428px] flex flex-col justify-between'
      active={active}
      setActive={setActive}
    >
      {/* content */}
      <div className='flex flex-col h-full'>
        <div className='flex justify-between items-center'>
          <h2 className='title'>Услуги</h2>
          <button
            onClick={() => setActive(false)}
            className='h-fit text-gray hover:text-blue'
          >
            <Close />
          </button>
        </div>

        <div className='max-h-[250px] overflow-y-auto'>
          {categories &&
            categories.data.map((item, index) => (
              <Fragment key={index}>
                <div
                  onClick={() => newFunc(item.name || item.id)}
                  className='flex flex-col mt-4 cursor-pointer'
                >
                  <button className={`flex justify-between h-fit`}>
                    <p className='truncate'>{item.name}</p>

                    <Checkbox
                      sx={{
                        padding: 0,
                        marginRight: 0.5,
                        color: "gray.main",
                        "&.Mui-checked": {
                          color: "secondary.main",
                        },
                      }}
                      checkedIcon={<KeyboardArrowUp />}
                      name={item.name}
                      icon={<KeyboardArrowDown />}
                      checked={shownSubs.includes(item.name)}
                    />
                  </button>
                  {mainService.includes(item.name) && item?.services?.length ? (
                    item?.services?.map(
                      ({ id: serviceId, name, price, duration }) => (
                        <Fragment key={serviceId}>
                          <div
                            key={serviceId}
                            onClick={(e) => {
                              e.stopPropagation()
                              addService({
                                category: {
                                  id: item.id,
                                  name: item.name,
                                },
                                id: serviceId,
                                name,
                                price,
                                duration,
                              })
                            }}
                            className='sm:ml-12 flex items-center h-fit cursor-pointer'
                          >
                            <MyCheckbox
                              value={selectedServices.some(
                                ({ id }) => id == serviceId
                              )} 
                              checked={selectedServices.some(
                                ({ id }) => id == serviceId
                              )}
                            />
                            <button className=''>{name}</button>
                          </div>
                        </Fragment>
                      )
                    )
                  ) : (
                    <p></p>
                  )}
                  {shownSubs.includes(item.name) &&
                    item?.subcategory?.map((obj, index) => (
                      <Fragment key={index}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation()
                            showServices(obj.name)
                          }}
                          className='flex items-end ml-6 cursor-pointer'
                        >
                          <button className={`w-full flex h-fit`}>
                            {obj.name}
                          </button>
                          {obj?.services?.length ? (
                            <Checkbox
                              sx={{
                                padding: 0,
                                marginRight: 0.5,
                                color: "gray.main",
                                "&.Mui-checked": {
                                  color: "secondary.main",
                                },
                              }}
                              checkedIcon={<KeyboardArrowUp />}
                              name={obj.name}
                              icon={<KeyboardArrowDown />}
                              checked={shownServices.includes(obj.name)}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        {shownServices.includes(obj.name) && (
                          <>
                            {obj?.services?.map(
                              ({ id: serviceId, name, price, duration }) => (
                                <Fragment key={serviceId}>
                                  <div
                                    key={serviceId}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      addService({
                                        category: {
                                          id: item.id,
                                          name: item.name,
                                        },
                                        id: serviceId,
                                        name,
                                        price,
                                        duration,
                                      })
                                    }}
                                    className='ml-12 flex items-center h-fit cursor-pointer'
                                  >
                                    <MyCheckbox
                                      value={selectedServices.some(
                                        ({ id: old }) => old === serviceId
                                      )} // checked
                                    />
                                    <button>{name}</button>
                                  </div>
                                </Fragment>
                              )
                            )}
                          </>
                        )}
                      </Fragment>
                    ))}
                </div>
              </Fragment>
            ))}
        </div>
      </div>

      {/* buttons */}
      <div className='grid grid-cols-2'>
        <button type='reset' onClick={reset} className='btnOutlined mr-2'>
          Отмена
        </button>
        <button type='submit' className='btnFilled ml-2' onClick={submit}>
          Добавить
        </button>
        {touched && !selectedServices.length && (
          <ErrorMsg title={"Вы ничего не добавили"} />
        )}
      </div>
    </Modal>
  )
}
